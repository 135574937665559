.login {
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0.75rem 0px 1.5rem;
    margin: 3rem 0px 0px;

    padding: 1.5rem 0px 0px;
    width: 368px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 1.529rem 1.882rem 0px;
    margin: 0px;
    white-space: pre-wrap;
    -webkit-box-flex: 1;
    flex-grow: 1;
    transition: none 0s ease 0s;
    box-sizing: border-box;
}

.login__panel {
    box-sizing: border-box;
    position: relative;
}

@media (min-width: 425px) {
    .login__panel {
        padding: 1.5rem 0px 0px;
        width: 368px;
    }
}
@media (min-width: 737px) {
    .login__panel {
        width: 368px;
        margin: 0 auto;
        margin-top: 40px;
        padding: 48px;
        border-radius: 0.5rem;
        background-color: rgb(29, 32, 35);
    }
}
.login__label {
    font: var(--font-h3-comp);
}
.login__top-content {
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.login__form {
    display: flex;
    flex-direction: column;
    gap: 17px;
    button {
        padding: 12px;
    }
}
.login__footer {
    text-align: center;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 1.3rem;
    padding: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 1.412rem;
    margin-bottom: 1.412rem;
    font: var(--font-p3-regular-comp);
    padding: 0px 1.176rem;
    color: rgb(150, 159, 168);
    overflow-wrap: break-word;
    word-break: break-word;
    width: 100%;
    transition: none 0s ease 0s;
    box-sizing: border-box;
    a {
        cursor: pointer;
        text-decoration: none;
        color: rgb(69, 182, 252);
    }
}

.login__close-button {
    position: absolute;
    top: -5px;
    right: 0px;
    padding: 4px !important;
    @media (min-width: 425px) {
        top: 16px;
    }
    & > svg {
        font-size: 24px;
    }
}

.login__close-button--outside {
    top: 16px;
    right: -42px;
}